import React, { lazy, useCallback } from 'react'
import Solvopro from '@improvity/solvopro-frontend'
import './styles/themes/default.less'
import { modules } from './constants'
import { queryClient } from './api/auth'

function App() {
  const Theme = lazy(() => import('./styles/themes/MassRecruitmentTheme'))
  const queryClientFunc = useCallback(() => queryClient, [])
  return (
    <Solvopro
      theme={Theme}
      moduleName={modules.bk}
      menuTheme="dark"
      queryClient={queryClientFunc}
    />
  )
}

export default App
