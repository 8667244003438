import * as React from 'react'
import { App } from 'antd'
import { createRoot } from 'react-dom/client'
import Root from './Root'

const container = document.getElementById('root')
const root = createRoot(container, { concurrent: true })

root.render(
  <React.StrictMode>
    <App>
      <Root />
    </App>
  </React.StrictMode>
)
