import { QueryClient } from 'react-query/core'
import { threeMinutes } from '../constants'

const logout = queryClient => () => {
  queryClient?.clear?.()
  localStorage.removeItem('token')
}

const getToken = () => localStorage.getItem('token') || ''

const setToken = token => localStorage.setItem('token', token)

const isLogged = () => !!getToken()

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: threeMinutes,
      retry: 1
    }
  }
})

export const auth = {
  logout,
  getToken,
  setToken,
  isLogged
}
