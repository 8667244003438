import './wdyr'
import React, { useEffect } from 'react'
import * as Sentry from '@sentry/react'
import { RecoilRoot, useRecoilSnapshot } from 'recoil'

import App from './App'
import reportWebVitals from './reportWebVitals'
import { getSentryOptions } from './helpers/setting'
import { ErrorLayout } from './Screens/ErrorLayout/ErrorLayout'

Sentry.init(getSentryOptions())
function DebugObserver() {
  const snapshot = useRecoilSnapshot()
  useEffect(() => {
    console.debug('The following atoms were modified:')
    for (const node of snapshot.getNodes_UNSTABLE({ isModified: true })) {
      console.debug(node.key, snapshot.getLoadable(node))
    }
  }, [snapshot])

  return null
}
function Root() {
  return (
    <RecoilRoot>
      <DebugObserver />
      <Sentry.ErrorBoundary fallback={<ErrorLayout />}>
        <App />
      </Sentry.ErrorBoundary>
    </RecoilRoot>
  )
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log)
// export default Root
export default Sentry.withProfiler(Root, { name: 'Solvopro BK' })
