import { reloginTokenExpiredMessage } from './index'

const workTime = ['полный день', 'не полный день']
const statusesValues = {
  new: 'New',
  rookie: 'Rookie',
  interviewScheduled: 'InterviewScheduled',
  cameForAnInterview: 'CameForAnInterview',
  notCameForAnInterview: 'NotCameForAnInterview',
  sentToMedicalTest: 'SentToMedicalTest',
  passedMedicalTest: 'PassedMedicalTest',
  readyToWork: 'ReadyToWork',
  workStarted: 'WorkStarted',
  rejectedByDepartment: 'RejectedByDepartment',
  rejectedByRecruiter: 'RejectedByRecruiter',
  rejectedByCandidate: 'RejectedByCandidate',
  rejectedByHM: 'RejectedByHM',
  inReserve: 'InReserve',
  toCallTheRobot: 'ToCallTheRobot',
  preInterview: 'PreInterview',
  problemsWithRobot: 'ProblemsWithRobot',
  duplicate: 'Duplicate',
  phoneNumberDoesNotExist: 'PhoneNumberDoesNotExist',
  attemptsToCallEnded: 'AttemptsToCallEnded',
  needToCallBack: 'NeedToCallBack',
  failToReach: 'FailToReach',
  irrelevant: 'Irrelevant',
  callToQRCode: 'CallToQRCode',
  longList: 'LongList',
  phoneInterview: 'PhoneInterview',
  hrInterview: 'HRInterview',
  hmInterview: 'HMInterview',
  cameToHRInterview: 'CameToHRInterview',
  notCameToHRInterview: 'NotCameToHRInterview',
  cameToHMInterview: 'CameToHMInterview',
  notCameToHMInterview: 'NotCameToHMInterview',
  headOfHMInterview: 'HeadOfHMInterview',
  cameToHeadOfHM: 'CameToHeadOfHM',
  notCameToHeadOfHM: 'NotCameToHeadOfHM',
  newAppointmentApproval: 'NewAppointmentApproval',
  jobOfferSubmitted: 'JobOfferSubmitted',
  jobOfferSigned: 'JobOfferSigned',
  hrBPInterview: 'HRBPInterview',
  cameToHRBPInterview: 'CameToHRBPInterview',
  notCameToHRBPInterview: 'NotCameToHRBPInterview',
  odInterview: 'ODInterview',
  cameToOD: 'CameToOD',
  notCameToOD: 'NotCameToOD',
  waitingForCheckup: 'WaitingForCheckup',
  checkupDocIssued: 'CheckupDocIssued'
}
const statusesValuesInboxOrder = [
  // в этом порядке будут показываться статусы в инбоксе
  statusesValues.preInterview,
  statusesValues.longList,
  statusesValues.needToCallBack,
  statusesValues.phoneInterview,
  statusesValues.hrInterview,
  statusesValues.cameToHRInterview,
  statusesValues.hrBPInterview,
  statusesValues.cameToHRBPInterview,
  statusesValues.odInterview,
  statusesValues.cameToOD,
  statusesValues.waitingForCheckup,
  statusesValues.sentToMedicalTest,
  statusesValues.passedMedicalTest,
  statusesValues.checkupDocIssued,
  statusesValues.inReserve,
  statusesValues.notCameToHRInterview,
  statusesValues.notCameToHRBPInterview,
  statusesValues.notCameToOD,
  statusesValues.notCameForAnInterview,
  statusesValues.toCallTheRobot,
  statusesValues.failToReach,
  statusesValues.attemptsToCallEnded,
  statusesValues.phoneNumberDoesNotExist,
  statusesValues.irrelevant,
  statusesValues.interviewScheduled,
  statusesValues.problemsWithRobot,
  statusesValues.duplicate
]
const statusesValuesAmpInboxOrder = [
  statusesValues.rejectedByRecruiter,
  statusesValues.rejectedByCandidate,
  statusesValues.rejectedByHM
]
const statusesValuesOfficeOrder = [
  // в этом порядке будут показываться статусы в офисе
  statusesValues.longList,
  statusesValues.phoneInterview,
  statusesValues.hrInterview,
  statusesValues.cameToHRInterview,
  statusesValues.notCameToHRInterview,
  statusesValues.hmInterview,
  statusesValues.cameToHMInterview,
  statusesValues.notCameToHMInterview,
  statusesValues.headOfHMInterview,
  statusesValues.cameToHeadOfHM,
  statusesValues.notCameToHeadOfHM,
  statusesValues.newAppointmentApproval,
  statusesValues.jobOfferSubmitted,
  statusesValues.jobOfferSigned,
  statusesValues.workStarted,
  statusesValues.inReserve,
  statusesValues.rejectedByCandidate,
  statusesValues.rejectedByRecruiter,
  statusesValues.rejectedByHM,
  statusesValues.duplicate
]
const applicationStatuses = [
  { key: statusesValues.new, label: 'Новый' },
  { key: statusesValues.rookie, label: 'Новые' },
  { key: statusesValues.interviewScheduled, label: 'Собеседование' },
  { key: statusesValues.cameForAnInterview, label: 'Пришел на собеседование' },
  { key: statusesValues.notCameForAnInterview, label: 'Не пришел на собеседование' },
  { key: statusesValues.hrBPInterview, label: 'Собеседование с РМП и ТУ' },
  { key: statusesValues.cameToHRBPInterview, label: 'Пришел к РМП и ТУ' },
  { key: statusesValues.waitingForCheckup, label: 'Ожидает направления на МО' },
  { key: statusesValues.sentToMedicalTest, label: 'Отправлен на медосмотр' },
  { key: statusesValues.passedMedicalTest, label: 'Прошел медосмотр' },
  { key: statusesValues.checkupDocIssued, label: 'ЛМК готова' },
  { key: statusesValues.readyToWork, label: 'Оформлен' },
  { key: statusesValues.workStarted, label: 'Вышел на работу' },
  { key: statusesValues.rejectedByDepartment, label: 'Отказ ресторана' },
  { key: statusesValues.rejectedByRecruiter, label: 'Отказ рекрутера' },
  { key: statusesValues.rejectedByCandidate, label: 'Отказ кандидата' },
  { key: statusesValues.rejectedByHM, label: 'Отказ руководителя' },
  // этот статус заменен на "rookie" но в базе еще может храниться - в коде его записи обьединятся в "rookie"
  { key: statusesValues.callToQRCode, label: 'Ожидает звонка' },
  { key: statusesValues.longList, label: 'Лонг-лист' },
  { key: statusesValues.odInterview, label: 'Направлен к ОД' },
  { key: statusesValues.cameToOD, label: 'Пришел к ОД' }
]

const planStatuses = {
  new: 'New',
  approved: 'Approved',
  rejected: 'Rejected',
  updatedBySystem: 'UpdatedBySystem',
  changedByHiringManager: 'ChangedByHiringManager'
}

const officeCandidateStates = {
  New: 'Новый',
  LongList: 'Лонг-Лист',
  PhoneInterview: 'Телефонное интервью',
  InReserve: 'В резерве',
  HRInterview: 'Интервью с HR',
  CameToHRInterview: 'Пришел к HR',
  NotCameToHRInterview: 'Не пришел к HR',
  HMInterview: 'Интервью с руководителем',
  CameToHMInterview: 'Пришел на интервью с руководителем',
  NotCameToHMInterview: 'Не пришел на интервью с руководителем',
  HeadOfHMInterview: 'Интервью с вышестоящим руководителем',
  CameToHeadOfHM: 'Пришел на интервью с вышестоящим руководителем',
  NotCameToHeadOfHM: 'Не пришел на интервью с вышестоящим руководителем',
  NewAppointmentApproval: 'Требуется согласование',
  JobOfferSubmitted: 'Выставлен job offer',
  JobOfferSigned: 'Принят job offer',
  WorkStarted: 'Вышел на работу',
  RejectedByRecruiter: 'Отказ рекрутера',
  RejectedByCandidate: 'Отказ кандидата',
  RejectedByHM: 'Отказ заказчика',
  Duplicate: 'Дубль'
}

const needsVacanciesValues = {
  cook: 'cook',
  cashier: 'cashier',
  director: 'director',
  trainer: 'trainer',
  deputyDirector: 'deputyDirector',
  shiftManager: 'shiftManager',
  dayCleaner: 'dayCleaner',
  nightCleaner: 'nightCleaner',
  driverCourier: 'driverCourier'
}

const vacanciesShortNames = {
  [needsVacanciesValues.cook]: 'Повар',
  [needsVacanciesValues.cashier]: 'Кассир',
  [needsVacanciesValues.trainer]: 'ТН', // Тренер наставник
  [needsVacanciesValues.dayCleaner]: 'ДУ', // Дневной уборщик
  [needsVacanciesValues.nightCleaner]: 'НУ', // Ночной уборщик
  [needsVacanciesValues.driverCourier]: 'ВК', // Водитель курьер
  [needsVacanciesValues.director]: 'ДР', // Директор ресторана
  [needsVacanciesValues.deputyDirector]: 'ЗДР', // Замдиректор ресторана
  [needsVacanciesValues.shiftManager]: 'МС' // Менеджер смены
}

const timeNames = {
  morning: 'Утро',
  noon: 'День',
  evening: 'Вечер',
  weekend: 'Выходные'
}

const roles = {
  recruiter: 'recruiter',
  admin: 'admin',
  hiringManager: 'hiringManager',
  manager: 'manager', // ТУ
  partner: 'partner', // РМП
  operationDirector: 'operationDirector'
}

const scopes = {
  massRecruitment: 'inbox',
  amp: 'amp',
  office: 'office'
}

const workflowsNames = {
  massRecruitment: 'crewman',
  office: 'deskdrones',
  amp: 'amp'
}
const possibleActions = {
  scheduleInterview: 'scheduleInterview',
  cameForAnInterview: 'cameForAnInterview',
  notCameForAnInterview: 'notCameForAnInterview',
  sendToMedicalTest: 'sendToMedicalTest',
  checkupDocIssued: 'checkupDocIssued',
  passedMedicalTest: 'passedMedicalTest',
  readyToWork: 'readyToWork',
  workStarted: 'workStarted',
  toCallTheRobot: 'toCallTheRobot',
  rejectedByCandidate: 'rejectedByCandidate',
  rejectedByDepartment: 'rejectedByDepartment',
  rejectedByRecruiter: 'rejectedByRecruiter',
  rejectedByHM: 'rejectedByHM',
  preInterview: 'preInterview',
  problemsWithRobot: 'problemsWithRobot',
  toReserve: 'toReserve',
  markAsDuplicate: 'markAsDuplicate',
  hrInterview: 'hrInterview',
  hmInterview: 'hmInterview',
  phoneInterview: 'phoneInterview',
  cameToHRInterview: 'cameToHRInterview',
  notCameToHRInterview: 'notCameToHRInterview',
  cameToHMInterview: 'cameToHMInterview',
  notCameToHMInterview: 'notCameToHMInterview',
  headOfHMInterview: 'headOfHMInterview',
  cameToHeadOfHM: 'cameToHeadOfHM',
  notCameToHeadOfHM: 'notCameToHeadOfHM',
  jobOfferSubmitted: 'jobOfferSubmitted',
  jobOfferSigned: 'jobOfferSigned',
  hrBPInterview: 'hrBPInterview',
  cameToHRBPInterview: 'cameToHRBPInterview',
  notCameToHRBPInterview: 'notCameToHRBPInterview',
  odInterview: 'odInterview',
  cameToOD: 'cameToOD',
  notCameToOD: 'notCameToOD',
  waitForCheckup: 'waitForCheckup'
}

export const appConfig = {
  formats: {
    shortDate: 'DD.MM.YYYY',
    shortTime: 'HH:mm',
    shortDateAndTime: 'DD.MM.YYYY HH:mm',
    dateAndTime: 'DD.MM.YYYY в HH:mm',
    shortDateApi: 'YYYY-MM-DD',
    shortDateCheckupApi: 'YYYY.MM.DD',
    shortDateAndTimeApi: 'YYYY-MM-DDTHH:mm:ss',
    excelShortDate: 'dd.mm.yyyy;@',
    excelShortTime: 'HH:mm;@'
  },
  interview: {
    defaultStartTime: '06:00',
    defaultEndTime: '22:00'
  },
  citizenships: ['РФ', 'Беларусь', 'Армения', 'Казахстан', 'Киргизия', 'Иное гражданство'],
  region: [
    'BASHKORTOSTAN',
    'CENTR',
    'DON',
    'HO',
    'MSK',
    'MSK MO EAST',
    'MSK MO NO-EAST',
    'MSK MO NORTH',
    'MSK MO SO-EAST',
    'MSK MO SO-WEST',
    'MSK MO SOUTH',
    'MSK MO STATIONS',
    'MSK MO WEST',
    'NWEST',
    'NWEST EAST',
    'NWEST NORTH',
    'NWEST SOUTH',
    'SOUTH',
    'SYBER',
    'SZFO DRIVENORTH',
    'TATARSTAN',
    'UDMURTIA',
    'URALS NORTH',
    'URALS SOUTH',
    'VOLGA'
  ],
  metroList: ['ст. Кандидатская', 'ст. Профессорская', 'ст. Студентческая', 'ст. Яснева'],
  division: ['Гаева И. В.', 'Косаченко Д. С.', 'Красильников И. В.', 'Макеева А. В.'],
  requestType: [
    'АК/Партнёры',
    'Акция "Приведи друга"',
    'Входящий звонок',
    'Отклик',
    'Холодный поиск'
  ],
  utm: {
    source: [
      'Avito',
      'HH',
      'JobLab',
      'Rabota.ru',
      'SuperJob',
      'Worki',
      'ZP.RU',
      'etc',
      'fb',
      'inst',
      'telegram',
      'vk',
      'Акция "Приведи друга"',
      'Баннер',
      'Визитка',
      'Воблер',
      'Газета 1',
      'Департамент успеха',
      'Знакомые',
      'Киоск',
      'Лайтбокс',
      'Лидогенерация',
      'Листовка 1',
      'Листовки  "Лапша"',
      'Мегафон',
      'Наклейка на двери',
      'Органический поток',
      'Плакат',
      'Подвес',
      'Реклама в ТЦ',
      'Реклама в автобусах',
      'Реклама в маршрутках',
      'Реклама в электричках',
      'Реклама на остановке',
      'Роллап',
      'Стенд',
      'Тейбл-Тент',
      'Телетел',
      'Трейлайнер',
      'Щит'
    ],
    campaign: [
      'Banner 1',
      'Banner 2',
      'Advertising 1',
      'Advertising 2',
      'etc.',
      'Авито',
      'HH',
      'Worki',
      'vk',
      'inst',
      'fb',
      'telegram',
      'Rabota.ru',
      'JobLab',
      'ZP.RU',
      'SuperJob',
      'cpc',
      'Yandex, Google',
      'Название подрядчика'
    ],
    medium: [
      'Акция "Приведи друга"',
      'Карьерный сайт',
      'Наружная реклама',
      'Партнёры',
      'Полиграфия',
      'Реклама в ресторане',
      'Рекомендация',
      'Рекрутинг Сайты',
      'Соц. сети'
    ]
  },
  roles,
  rolesLabels: {
    [roles.recruiter]: 'Рекрутер',
    [roles.admin]: 'Центральный менеджер',
    [roles.hiringManager]: 'Директор',
    [roles.manager]: 'Территориальный управляющий',
    [roles.partner]: 'HR Бизнес партнер',
    [roles.operationDirector]: 'Операционный директор'
  },
  scopes,
  scopesLabels: {
    [scopes.massRecruitment]: 'ЧБР',
    [scopes.amp]: 'АУП',
    [scopes.office]: 'ОФИС'
  },
  planStatuses,
  planFactStatuses: {
    [planStatuses.new]: 'Новый',
    [planStatuses.approved]: 'Утверждено ТУ',
    [planStatuses.rejected]: 'Отклонено ТУ',
    [planStatuses.updatedBySystem]: 'Обновлено системой',
    [planStatuses.changedByHiringManager]: 'Не утверждено ТУ'
  },
  officeCandidateStates,
  schedulerWeekNavigate: {
    today: 'Сегодня',
    previous: 'Предыдущая',
    next: 'Следующая'
  },
  preferences: {
    rates: workTime,
    times: Object.values(timeNames).map(name => name.toLowerCase())
  },
  oris: {
    sex: [
      { value: 'male', text: 'Мужской' },
      { value: 'female', text: 'Женский' }
    ],
    allDocuments: [
      { value: true, text: 'Полный' },
      { value: false, text: 'Не полный' }
    ],
    schedule: [
      { value: 'full', text: workTime[0] },
      { value: 'partial', text: workTime[1] }
    ]
  },
  statuses: {
    values: statusesValues,
    inboxOrder: statusesValuesInboxOrder,
    ampInboxOrder: [...statusesValuesInboxOrder, ...statusesValuesAmpInboxOrder],
    officeOrder: statusesValuesOfficeOrder,
    application: applicationStatuses,
    candidate: [
      ...applicationStatuses,
      { key: statusesValues.inReserve, label: 'В резерве' },
      { key: statusesValues.duplicate, label: 'Дубль' },
      { key: statusesValues.toCallTheRobot, label: 'На обзвон роботом' },
      { key: statusesValues.preInterview, label: 'Преинтервью HR' },
      { key: statusesValues.problemsWithRobot, label: 'Проблемы робота' },
      { key: statusesValues.phoneNumberDoesNotExist, label: 'Номер не существует' },
      { key: statusesValues.attemptsToCallEnded, label: 'Исчерпаны попытки дозвона' },
      { key: statusesValues.needToCallBack, label: 'Необходимо перезвонить' },
      { key: statusesValues.failToReach, label: 'Недозвон' },
      { key: statusesValues.irrelevant, label: 'Неактуально' },
      { key: statusesValues.phoneInterview, label: 'Телефонное интервью' },
      { key: statusesValues.hrInterview, label: 'Интервью с HR' },
      { key: statusesValues.cameToHRInterview, label: 'Пришел к HR' },
      { key: statusesValues.notCameToHRInterview, label: 'Не пришел к HR' },
      { key: statusesValues.notCameToHRBPInterview, label: 'Не пришел к РМП и ТУ' },
      { key: statusesValues.notCameToOD, label: 'Не пришел к ОД' }
    ]
  },
  noAuthActions: [
    possibleActions.cameToHMInterview,
    possibleActions.notCameToHMInterview,
    possibleActions.cameToHeadOfHM,
    possibleActions.notCameToHeadOfHM
  ],
  workFlows: {
    candidate: {
      prefixUrl: 'flow/',
      actions: possibleActions
    },
    call: {
      prefixUrl: 'flow/call/',
      actions: {
        later: 'later', // перезвонить позже
        missed: 'missed' // не дозвонился
      }
    }
  },
  candidateScopes: ['inbox'],
  references: {
    citizenships: 'citizenships',
    utms: 'utm?requestType=',
    requestTypes: 'requestTypes',
    sources: 'utm/sources',
    mediums: 'utm/mediums',
    divisions: 'divisions',
    regions: 'regions',
    inboxStates: 'inboxStates',
    cities: 'cities',
    positions: 'positions'
  },
  interviewHours: {
    start: 9,
    end: 22
  },
  settings: {
    sms: 'SMS',
    interviewScheduler: {
      start: 'INTERVIEW_SCHEDULE_HOUR_START',
      end: 'INTERVIEW_SCHEDULE_HOUR_END'
    },
    rejectionTypes: {
      candidate: 'REJECTED_BY_CANDIDATE',
      department: 'REJECTED_BY_DEPARTMENT',
      recruiter: 'REJECTED_BY_RECRUITER',
      hiringManager: 'REJECTED_BY_HIRING_MANAGER',
      candidateOffice: 'REJECTED_BY_CANDIDATE_OFFICE',
      recruiterOffice: 'REJECTED_BY_RECRUITER_OFFICE',
      hiringManagerOffice: 'REJECTED_BY_HIRING_MANAGER_OFFICE'
    },
    links: {
      hh: 'AUTHORIZATION_LINK_HH',
      zarplata: 'AUTHORIZATION_LINK_ZARPLATA'
    }
  },
  nextPlanningAvailableDate: 25,
  needsVacancies: {
    values: needsVacanciesValues,
    labels: {
      [needsVacanciesValues.cook]: 'Повар',
      [needsVacanciesValues.cashier]: 'Кассир',
      [needsVacanciesValues.director]: 'Директор ресторана',
      [needsVacanciesValues.deputyDirector]: 'Заместитель директора ресторана',
      [needsVacanciesValues.shiftManager]: 'Менеджер смены',
      [needsVacanciesValues.dayCleaner]: 'Дневной уборщик',
      [needsVacanciesValues.nightCleaner]: 'Ночной уборщик',
      [needsVacanciesValues.driverCourier]: 'Водитель курьер'
    }
  },
  vacanciesShortNames,
  timeNames,
  userFormModes: {
    create: 'create',
    edit: 'edit'
  },
  vacancyPriorityVariants: [
    { label: 'Обычный', value: 'medium' },
    { label: 'Низкий', value: 'low' },
    { label: 'Высокий', value: 'high' }
  ],
  vacancyVisibilityVariants: [
    { label: 'Видна всем', value: 'all' },
    { label: 'Видна работающим над вакансией', value: 'responsible' },
    { label: 'Видна только мне', value: 'creator' }
  ],
  vacancyStateVariants: ['Closed', 'Suspended', 'Opened'],
  workflowsNames,
  departmentUsers: {
    hrBpEmail: 'hrBpEmail',
    managerEmail: 'managerEmail',
    operationDirectorEmail: 'operationDirectorEmail'
  },
  reportSourceTypes: {
    candidates: 'candidates',
    applications: 'applications',
    workSchedule: 'workSchedule'
  },
  // На инбоксе будут использоваться фильтры, всевозможные значения которых будут получаться из запроса.
  inboxFilters: {
    requestType: 'requestType',
    utmSource: 'utm_source'
  },
  checkup: {
    massRecruitmentVirtualId: 42424242,
    ampVirtualId: 84848484
  },
  requestTypeCareerSite: 'Отклик из карьерного сайта',
  messagesStates: {
    error: 'Error',
    read: 'ReadByRecruiter',
    sent: 'Sent',
    delivered: 'Delivered'
  },
  messageTypes: {
    statusChange: 'statusChange',
    user: 'user',
    system: 'system'
  },
  exchangeErrors: {
    UserNotRegistered: 'Пользователь не авторизован. Пожалуйста, авторизуйтесь по ссылке',
    UserTokenExpired: reloginTokenExpiredMessage
  },
  officeRejectedStatuses: [
    statusesValues.rejectedByRecruiter,
    statusesValues.rejectedByCandidate,
    statusesValues.rejectedByHM,
    statusesValues.duplicate
  ]
}
