import * as Sentry from '@sentry/react'
import { useEffect } from 'react'
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes
} from 'react-router-dom'
import { appConfig } from '../constants/appConfig'
import { getEnvironmentByHost } from './index'
export const parseRejectionSetting = value => (value ? JSON.parse(value) : [])

export const stringifyRejectionSetting = (list = []) =>
  JSON.stringify(list.filter(item => item.trim().length))

export function getSaveMessage(data) {
  let target
  switch (true) {
    case data.config.url.endsWith(appConfig.settings.rejectionTypes.candidate):
      target = ' кандидата'
      break
    case data.config.url.endsWith(appConfig.settings.rejectionTypes.department):
      target = ' ресторана'
      break
    case data.config.url.endsWith(appConfig.settings.rejectionTypes.recruiter):
      target = ' рекрутера'
      break
    case data.config.url.endsWith(appConfig.settings.rejectionTypes.hiringManager):
      target = ' руководителя'
      break
    default:
      target = ''
  }
  return `Настройка отказов${target} сохранена`
}

export function getSentryOptions() {
  const environment = getEnvironmentByHost()

  return {
    dsn: 'https://00ebaaafca42467ebb3eb2bac1feb122@sentry.improvity.ru/3',
    debug: true,
    environment,
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: [
          'http://localhost:3000/',
          /^http:\/\/localhost:3000\/api/,
          /^https:\/\/bk-test\.solvopro\.improvity\.ru\/api/,
          'localhost'
        ],
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        )
      }),
      new Sentry.Replay()
    ],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    tracesSampleRate: 1.0,
    release: 'bk-solvopro@' + window.solvopro?.version,
    ignoreErrors: [
      'ResizeObserver loop limit exceeded',
      "Cannot find module './helpers/sentry/init'"
    ]
  }
}

export const closeIframe = () => {
  /**
   * отправляем сообщение нашему расширению чтобы удалить iframe с приложением из дома стороннего сайта
   */
  window.parent?.postMessage({ name: 'close-takemycall-external-office' }, '*')
}

/**
 * Сравниваем значения в 'state' и 'context' по ключу 'key'
 * Если различаются то сохраняем значения из 'state' в БД
 *
 * @param {String} key
 * @param {Object} state
 * @param {Object} context
 * @param {Function} updateData
 * @param {Function} updateValue - необязательный
 */
export const checkSettingsChangesAndUpdate = async ({
  key,
  state,
  context,
  updateData,
  updateValue
}) => {
  if (!key || !state || !context || !updateData) {
    return console.error('Проверьте что корректно передали все параметры')
  }
  const value = updateValue ? updateValue(state[key]) : state[key]
  if (value !== context[key]) {
    await updateData({
      code: key,
      value
    })
  }
}
