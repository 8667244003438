import React from 'react'
import Text from 'antd/lib/typography/Text'

export const defaultTitle = <Text type="secondary">не указан</Text>
export const emptyTitle = <Text type="secondary">отсутствует</Text>
export const emailDefaultTitle = <Text type="secondary">email не указан</Text>
export const apiErrorSaveMsg = <Text type="danger">Ошибка сохранения данных</Text>
export const nbsp = '\xa0'
export const apiErrorSaveOris = (
  <Text type="danger">
    Ресторан не найден в системе {nbsp}
    <b>DAX</b>. Заведите ресторан и попробуйте снова.
  </Text>
)
export const textTrue = 'да'
export const textFalse = 'нет'

/**
 * Dadata constants
 */
export const dadataUrl = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address'
export const metroDadataUrl = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/metro'
export const dadataToken = 'e4e40464a235cf175752186948ab1982e64eddcb'

export const emptyFilter = { text: '<Нет значения>', value: null }

export const threeMinutes = 3 * 60 * 1000
export const recoveryKeyPrefix = '?recoveryKey='

export const modules = {
  massRecruitment: 'massRecruitment',
  bk: 'BK',
  office: 'office'
}

export const externalName = 'takemycall-external-office'
export const noAuthPrefix = 'noAuth'
export const emailSignatureDelimiter = '\n\n--\n\n'
export const imagesPrefix = '/images/'

export const countOfVacanciesList = 7
export const minSymbolForCollapseOnExternalFrame = 200

export const reloginTokenExpiredMessage =
  'Истек срок действия авторизации для получения писем. Пожалуйста, авторизуйтесь по ссылке'

/**
 * При вводе в инпут селектора количество симвоов обычно ограничено
 * После выбора любой из опций в инпут попадает ID выбранного элемента что приводит к лишнему запросу с этим ID
 * @type {number}
 */
export const countOfSymbolsInSearchFieldLikeId = 21

export const defaultQueryOptions = {
  retry: false,
  refetchOnMount: false,
  refetchOnReconnect: false,
  refetchOnWindowFocus: false,
  cacheTime: Infinity,
  staleTime: Infinity
}
export const countRowsForVirtualTable = 30

export const emptyRouteParams = {
  id: null,
  year: null,
  month: null,
  departmentId: null
}
